import { Splide } from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

window.addEventListener("turbolinks:load", function () {
  $.ajax("area/external_data")
    .done(function () {
      mountSplide("splide-municipality-coordination-container", {
        fixedWidth: "240px",
      });
      mountSplide("splide-media-logo-container", {
        autoWidth: true,
        focus: 0,
        omitEnd: false,
      });
    })
    .fail(function () {
      // TODO: retry
    });

  function mountSplide(containerElementId, options) {
    const container = document.getElementById(containerElementId);

    const defaultOptions = {
      gap: 16,
      type: "loop",
      arrows: false,
      drag: "free",
      pagination: false,
      flickPower: 300,
      autoScroll: {
        speed: 0.25,
        pauseOnHover: false,
        pauseOnFocus: false,
      },
      breakpoints: {
        1199: {
          gap: 8,
        },
      },
    };

    if (container != null) {
      const splide = new Splide(container, { ...defaultOptions, ...options });
      splide.mount({ AutoScroll });
    }
  }
});
